import Head from "next/head";
import { type Article, allArticles } from "contentlayer/generated";
import { compareDesc } from "date-fns";
import { useEffect, useState } from "react";
import Card from "@/components/Card";
import PageLayout from "@/components/Layout/PageLayout";
import { useRouter } from "next/router";
import Logo from "@/components/Logo";

export default function Home() {
  const [articles, setArticles] = useState<Article[]>([]);
  const { locale } = useRouter();

  useEffect(() => {
    function getArticles() {
      setArticles(
        allArticles
          .filter((article) => article.locale === locale)
          .sort((a, b) => compareDesc(new Date(a.date), new Date(b.date))),
      );
    }
    getArticles();
  }, [locale]);

  return (
    <>
      <Head>
        <title>{`${locale === "en" ? "Home" : "Inicio"} | treegrow.dev`}</title>
        <link
          rel="canonical"
          href={
            locale === "en" ? "https://treegrow.dev" : "https://treegrow.dev/es"
          }
        />
        <link rel="alternate" hrefLang="en" href="https://treegrow.dev" />
        <link rel="alternate" hrefLang="es" href="https://treegrow.dev/es" />
        <meta
          name="keywords"
          content="dev, development, desarrollo, learning, aprendizaje, learn, aprender, blog, treegrow, tree, grow, javascript, programming, programacion, software, react, nextjs, next, technology, tecnologia, articles, articulos, best, mejores, practices, practicas, fundaments, fundamentos, concepts, conceptos"
        />
        <meta
          name="description"
          content={
            locale === "en"
              ? `Unlock the world of software development for free with our beginner-friendly explanations. Start learning now!`
              : `Descubre el mundo del desarrollo de software de forma gratuita con nuestras explicaciones amigables para principiantes. ¡Comienza a aprender ahora!`
          }
        />
        <meta
          itemProp="name"
          content={`${locale === "en" ? "Home" : "Inicio"} - treegrow.dev`}
        />
        <meta
          itemProp="description"
          content={
            locale === "en"
              ? `Unlock the world of software development for free with our beginner-friendly explanations. Start learning now!`
              : `Descubre el mundo del desarrollo de software de forma gratuita con nuestras explicaciones amigables para principiantes. ¡Comienza a aprender ahora!`
          }
        />

        <meta itemProp="image" content="/android-chrome-512x512.png" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        {typeof window !== "undefined" && (
          <meta property="og:url" content={window.location.href ?? ""} />
        )}
        <meta
          property="og:title"
          content={`${locale === "en" ? "Home" : "Inicio"} | treegrow.dev`}
        />
        <meta
          property="og:description"
          content={
            locale === "en"
              ? `Unlock the world of software development for free with our beginner-friendly explanations. Start learning now!`
              : `Descubre el mundo del desarrollo de software de forma gratuita con nuestras explicaciones amigables para principiantes. ¡Comienza a aprender ahora!`
          }
        />
        <meta property="og:image" content="/android-chrome-512x512.png" />

        <meta property="twitter:card" content="summary_large_image" />
        {typeof window !== "undefined" && (
          <meta property="twitter:url" content={window.location.href ?? ""} />
        )}
        <meta
          property="twitter:title"
          content={`${locale === "en" ? "Home" : "Inicio"} | treegrow.dev`}
        />
        <meta
          property="twitter:description"
          content={
            locale === "en"
              ? `Unlock the world of software development for free with our beginner-friendly explanations. Start cultivating your knowledge now!`
              : `Descubre el mundo del desarrollo de software de forma gratuita con nuestras explicaciones amigables para principiantes. ¡Comienza a cultivar tu conocimiento ahora!`
          }
        />
        <meta property="twitter:image" content="/android-chrome-512x512.png" />
      </Head>
      <PageLayout>
        <div className="mx-auto mt-12 grid w-full max-w-5xl gap-16 bg-transparent">
          <div className="mx-auto">
            <h1 className="mb-4 whitespace-pre-line px-8 text-center text-4xl font-extrabold text-slate-300 md:text-5xl">
              {locale === "en"
                ? "Cultivate your knowledge now!"
                : "Cultiva tu conocimiento ahora!"}
            </h1>
            <div className="mx-auto flex items-center justify-center gap-4 text-center ">
              <span className="text-lg font-bold text-gray-500">
                treegrow.dev
              </span>
              <Logo className="h-6 w-6" />
            </div>
          </div>
          <div className="grid w-full grid-cols-auto-fill-1 justify-center gap-x-16 gap-y-10 ">
            {articles.map(({ ...props }) => (
              <Card key={props._id} {...props} />
            ))}
          </div>
        </div>
      </PageLayout>
    </>
  );
}

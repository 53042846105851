import { getLogoByTagName } from "@/utils/logos";
import type { Article } from "contentlayer/generated";
import Badge from "@/components/Badge";
import Link from "next/link";
import Logo from "./Logo";

export function getColorByTagName(tag: string) {
  switch (tag) {
    case "react":
      return "bg-[#61dafb]";
    case "javascript":
      return "bg-[#f0db4f]";
    default:
      return "bg-gray-500";
  }
}

function Card({ title, subtitle, tags, url }: Article) {
  return (
    <Link
      href={url}
      className="group flex h-full w-full transform flex-col rounded-md border border-gray-800 bg-gray-800 transition-transform hover:scale-105 hover:cursor-pointer hover:shadow-2xl  sm:mx-auto"
    >
      <div className="flex flex-col">
        <div className="relative flex h-44 w-full items-center justify-center gap-4 rounded-tl-md rounded-tr-md  bg-gradient-to-b from-gray-900 to-gray-700">
          <div
            className={`absolute left-0 h-full w-[2px] rounded-tl-md rounded-tr-md ${
              tags[0] && getColorByTagName(tags[0])
            }`}
          ></div>
          <div className="absolute right-2 top-2 flex items-center gap-2">
            <span className="text-sm text-gray-500">treegrow.dev</span>{" "}
            <Logo className="h-4 w-4" />
          </div>
          <span className="mx-auto mt-2 whitespace-pre-line px-8 text-center text-2xl font-semibold text-slate-200 md:text-3xl">
            {title.replaceAll("\\n", "\n")}
          </span>
        </div>

        <div className="flex w-full flex-col p-4">
          <span className="text-base font-light text-gray-100 group-hover:text-sky-600 md:text-lg">
            {subtitle}
          </span>
        </div>
      </div>

      <div className="flex h-full flex-col justify-end">
        <div className="relative flex items-center justify-between p-4">
          <div className="flex max-w-[85%] flex-wrap items-center gap-2">
            {tags.map((tag) => (
              <Badge key={tag}> #{tag} </Badge>
            ))}
          </div>
          <span className="absolute bottom-4 right-3">
            {tags[0] && getLogoByTagName(tags[0], "w-8 h-8 rounded-md")}
          </span>
        </div>
      </div>
    </Link>
  );
}

export default Card;
